import React, { useEffect, useRef, useContext } from "react";
import "./styles/main.scss";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'

export default function Question(props) {
 
  let didLoad=false;
  const { state, dispatch } = useContext(GlobalContext);
  const { uuid } = useParams();
  const [question,setQuestion]=React.useState(state.questions.filter(question=>question.uuid===uuid)[0]);    
  const [loading,setLoading]=React.useState(false);    
  const [input,setInput]=React.useState('');
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const lastItemRef = useRef(null);

  const handleTimeUpdate = () => {
    if (videoRef.current.currentTime>38 && videoRef.current.currentTime<39) {
      videoRef.current.currentTime = 27;
    }
  };


  useEffect(() => {
    
    if (!question.processed && !didLoad) {
      didLoad=true;
      doRequest();        
    }
    videoRef.current.currentTime = 27;
    
  }, []);

  /**
   * Do request
   * @param {*} question 
   */
  const doRequest= async(newQuestion=false)=>{
    setLoading(true);
    let requestData={
        persona:state.persona,
        question: newQuestion?newQuestion:question.question,
        messageHistory:question.messageHistory,
        conversationId:question.conversationId?question.conversationId:0
    }

    if (newQuestion) {
        requestData.messageHistory.push({"role":"user", "content":newQuestion});
        let newQuestionData={...question};
        newQuestionData.conversation.push({"question":newQuestion});
        console.log("question",question)
        setQuestion(newQuestionData)
    }


    let result=await fetch("https://avoord-transitietafel-api-acc.lwdev.nl/api/question",{
        method:"POST",
        body:JSON.stringify(requestData),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    result=await result.json();

    let newData={...question};
    newData.conversationId=result.conversationId;
    newData.messageHistory=[...newData.messageHistory,
      {"role":"user", "content":newQuestion?newQuestion:question.question},
      {"role":"assistant", "content":result.answer?.body}
    ];
    newData.conversation.push(result);

    updateQuestion(newData)
    setLoading(false);
    setTimeout(function() {
      lastItemRef.current.scrollIntoView({ behavior: 'smooth' });
    },100);
  }


  // Update question data
  const updateQuestion=(data)=>{
    let newState={...state};
    for (let i=0;i<newState.questions.length;i++) {
        if (newState.questions[i].uuid===uuid) {
          newState.questions[i]=data;
          newState.questions[i].conversationId=data.conversationId;
          newState.questions[i].processed=true;
        }
    }
    dispatch({ type: "SET", payload: newState });
    setQuestion(data);
  }

  const createQuestion=()=>{
    doRequest(input);
    setInput('');
  }

  const changeInput=(e)=>{
    setInput(e.target.value);
    if (e.key === 'Enter') {
      createQuestion();
    }
  }  

  const LinkRenderer=(props: any)=>{
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }

  return (
    <div className="chat">

        <div className="chat__back">
          <a href="/" className="chat__back__link">Terug</a>
        </div>

        <div className="chat__video-container">
            <video
                ref={videoRef}
                src="/video.webm"
                muted
                autoPlay
                className="background__video"
                onTimeUpdate={handleTimeUpdate}
            >
               <source src="/video.webm" type="video/webm" />
            </video>
      </div>

      <div className="chat__overview">
        {question.conversation.map((message, index) => (
            <div  ref={index === question.conversation.length - 1 ? lastItemRef : null} className={"chat__message "+(message.question?'chat__message--right':'') } key={'message'+index}>
                {message.question && (
                    <div className="chat__message__question">{message.question}</div>
                )}

              {message.answer && (
                  <div className="chat__message__answer" >
                      <div className="chat__message__answer__title">
                      {message.answer.title}
                        </div>
                      <div className="chat__message__answer__copy">
                         <Markdown components={{ a: LinkRenderer }}>{message.answer.body}</Markdown>
                      </div>
                      {message.context[0] && message.context[0].media[0]?.link && index==1 && (
                        <div className="chat__message__answer__image">
                          <img src={'/images/'+message.context[0].media[0].link} />
                        </div>
                      )}
                      {message.context && message.context.length>0 && (
                         <div className="chat__message__answer__links">
                            <div className="chat__message__answer__links__label">Lees meer:</div>
                          
                            {message.context.map((link, index) => (
                                <div key={"link"+index} className="chat__message__answer__links__item">
                                  {link.favicon && (
                                  <img src={link.favicon} />
                                  )}
                                  <a href={link.url} target="_blank" className="chat__message__answer__link" key={'link'+index} rel="noreferrer">
                                    {link.title.trim()}
                                  </a>
                                </div>
                            ))}
                           
                          </div>
                      )}
                  </div>
              )}

            </div>   
            
        ))}

        {loading && (<div className="chat__loader">
            <div class="loader"></div>
            <div className="chat__loader__label">
              
              Evi is het antwoord aan het opzoeken</div>
        </div>)}

        {!loading && (
        <div className="chat__followup">
            {question.conversation[question.conversation.length-1].followUp && question.conversation[question.conversation.length-1].followUp.length>0 && (
                <div className="chat__followup__button"  role="button" onClick={()=>doRequest(question.conversation[question.conversation.length-1].followUp[0].question)}>{question.conversation[question.conversation.length-1].followUp[0].title}</div>
            )}
      
            <div className="chat__followup__button" role="button" onClick={()=>navigate('/')}>Start een nieuwe vraag</div>
        </div>
        )}

        {!loading && (
        <div className="chat__input__container">
          <input
            className="chat__input"
            type="text"
            onKeyDown={changeInput}
            onChange={(e)=>setInput(e.target.value)}
            value={input}
            placeholder="Je vervolgvraag"
          />
          <div className="chat__input__button"  onClick={createQuestion}>Verstuur</div>
        </div>)}

      </div>

       
    </div>
  );
}
