import React, { useEffect, useRef, useContext } from "react";
import "./styles/main.scss";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import standardQuestions from './standardQuestions.json';
import { v4 as uuidv4 } from 'uuid';

export default function Home(props) {
  const [questions, setQuestions] = React.useState([]);
  const [input, setInput] = React.useState('');
  const videoRef = useRef(null);
  const { state, dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  
  const handleTimeUpdate = () => {
    if (videoRef.current.currentTime<25 && videoRef.current.currentTime>24) {
      videoRef.current.currentTime = 12;
    }
  };

  useEffect(() => {
    let questionsList=[];
    if (state.questions) {
      questionsList=state.questions.slice(-3);
    }

    if (questionsList.length<3) {
      questionsList=[...questionsList, ...standardQuestions.slice(0, 3-questionsList.length)];
    }
    questionsList.reverse();
    setQuestions(questionsList);
    videoRef.current.currentTime = 12;
  }, []);


  /**
   * Open a question
   */
  const openQuestion = (originalQuestion) => {
    if (!state.questions) {
      state.questions=[];
    }

    let newQuestion={
      uuid: uuidv4(),
      question: originalQuestion.question,
      standardQuestion: originalQuestion.standardQuestion,
      answers: [],
      messageHistory:[],
      conversation:[
        {
          "question": originalQuestion.question,
        }
      ],
      processed: 0
    };
    state.questions.push(newQuestion);
    dispatch({ type: "SET", payload: state });

    navigate("/chat/"+newQuestion.uuid);
  }

  const createQuestion=()=>{
    openQuestion({question:input, standardQuestion:0})
  }

  const changeInput=(e)=>{
    if (e.key === 'Enter') {
      createQuestion();
    }
  }  

  return (
    <div className="home">
      <video
        ref={videoRef}
        src="video.webm"
        muted
        autoPlay
        className="background__video"
        onTimeUpdate={handleTimeUpdate}
      >
        <source src="/video.webm" type="video/webm" />
      </video>
      <div class="home__container">
        <div className="home__input__container">
          <input
            className="home__input"
            onKeyDown={changeInput}
            onChange={(e)=>setInput(e.target.value)}
            value={input}
            type="text"
            placeholder="Wat is je vraag?"
          />
          <div className="home__input__button" onClick={createQuestion}>Verstuur</div>
        </div>
        <div className="home__questions">
          {questions.map((question, index) => (
          <div className="home__question" key={'q'+index} onClick={()=>openQuestion(question)}>
            <div className="home__question__title">{question.question}</div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
}
