import React, { createContext, useReducer,useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import "./styles/main.scss";

export default function Buddy(props) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  const setBuddy = (buddy) => {
    // Go to route
    navigate("/onboarding");
  };

  useEffect(() => {
    if (state.persona) {
      navigate("/home");
    }
  }, []);

  return (
    <div className="buddy">
      <div className="buddy__container">
        <div className="buddy__title">Kies jouw buddy</div>
        <div className="buddy__description">
          Deze buddy helpt je bij het zoeken naar informatie over de problemen
          die je hebt. Kies de buddy uit die het beste bij je past.
        </div>
        <div class="buddy__options">
          <div
            class="buddy__option buddy__option--1"
            onClick={() => setBuddy("evi")}
          >
            <div class="buddy__option__image"></div>
            <div className="buddy__option__title">Evi</div>
          </div>
          <div class="buddy__option buddy__option--2">
            <div class="buddy__option__image"></div>
            <div className="buddy__option__title">Esther</div>
            <div className="buddy__option__alert">Nog niet beschikbaar</div>
          </div>
          <div class="buddy__option buddy__option--3">
            <div class="buddy__option__image"></div>
            <div className="buddy__option__title">Marc</div>
            <div className="buddy__option__alert">Nog niet beschikbaar</div>
          </div>
        </div>
      </div>
    </div>
  );
}
